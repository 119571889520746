import Image from 'next/image';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import DynamicHero from '@/app/components/platform/DynamicHero';
import { ImageWrapper } from './styles';
import { getImgData } from '@/utils/images';

const HeaderImage = ({
  content,
  logo,
  showSwordLogo = true,
  noSpacerBottom,
  userName,
  image: imageObject,
}) => {
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_md}px)`);

  const { image, imageMobile } = content.image ?? imageObject;

  const imageData = getImgData(image);
  const imageMobileData = getImgData(imageMobile);

  const selectedImage = isMobile && imageMobileData?.url ? imageMobileData : imageData;
  const altText =
    isMobile && imageMobile?.alternativeText
      ? imageMobile.alternativeText
      : image?.alternativeText || 'Header image';

  return (
    <DynamicHero
      content={content?.content ?? content}
      logo={logo}
      showSwordLogo={showSwordLogo}
      noSpacerBottom={noSpacerBottom}
      userName={userName}
    >
      <ImageWrapper>
        <Image
          id="hero-image"
          src={selectedImage?.url}
          alt={altText}
          loading="eager"
          priority={true}
          sizes="(max-width: 768px) 100vw, 50vw"
          fill
        />
      </ImageWrapper>
    </DynamicHero>
  );
};

export default HeaderImage;
