import { Card } from '@/app/components/platform/GridCards/style';
import Image from 'next/image';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import { Paragraph, Heading } from '@swordhealth/ui-corporate';

export const CustomCard = ({ id, image, title, description, cards }) => {
  const cardImage = getImgData(image);

  return (
    <Card $cards={cards}>
      <Image
        id={`${id}_image`}
        src={getImgSrcWorkaround(cardImage?.url)}
        alt={cardImage?.alternativeText || ''}
        width={80}
        height={80}
      />
      <Heading id={`${id}_title`} as="h6" weight="bold" size="lg">
        {title}
      </Heading>
      <Paragraph id={`${id}_description`} size="md" weight="regular">
        {description}
      </Paragraph>
    </Card>
  );
};
