import styled, { css } from 'styled-components';

export const Icon = styled.div`
  width: 16px;
  height: 16px;
`;

export const Number = styled.div`
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  font-family: ${(props) => props.theme.typography.family.title};
  font-style: normal;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary.light};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
`;

export const Wrapper = styled.button`
  align-items: baseline;
  border-radius: 16px;
  padding: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 16px;
  transition: all 120ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.neutral.dark};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    width: 100%;
    ${(props) =>
      props.$isActive &&
      css`
        background-color: ${(props) => props.theme.colors.neutral.default};

        &:hover {
          background-color: ${(props) => props.theme.colors.neutral.default};
        }
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    padding: 0;
    cursor: auto;
    &:hover {
      background-color: unset;
    }
  }
`;

export const Content = styled.div`
  align-items: start;
  display: grid;
  gap: ${(props) => (props.$isActive ? '16px' : 'initial')};
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }
`;

export const TitleInner = styled.div`
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    p:first-of-type {
      font-size: 16px;
      line-height: 28px;
    }
  }
`;
export const IconTitle = styled.span`
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  transition: all 120ms ease-in-out;
  transform: rotate(${(props) => (props.$isActive ? '0deg' : '180deg')});
  display: none;
  margin-top: 2px;

  @media (min-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: block;
  }
`;

export const Time = styled.div`
  background-color: #efeae6;
  border-radius: 20px;
  padding: 5px 8px;
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: left;
  vertical-align: center;
  flex-direction: row;
  gap: 8px;
  width: fit-content;
`;
