import styled from 'styled-components';
import { Paragraph } from '@swordhealth/ui-corporate';

export const ImageContainer = styled.div`
  width: 100%;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    width: 100%;
    height: 92px;
  }
`;

export const ImageWrapper = styled.figure`
  position: relative;
  width: 80px;
  height: 80px;

  img {
    object-fit: contain;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    width: 64px;
    height: 64px;
  }
`;

export const CardContainer = styled.div`
  -webkit-tap-highlight-color: transparent;
  aspect-ratio: 1 / 1;
  width: 100%;
  box-sizing: border-box;
  cursor: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.neutral.default};
  user-select: none;
  user-drag: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    padding: 24px;
  }
`;

export const Text = styled(Paragraph)`
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 auto;

  svg {
    margin-left: 6px;
  }
`;
