import { Row } from '@/components/ui/flexbox';
import Section, { SPACE_DESKTOP, SPACE_MOBILE, SPACE_TABLET } from '@/components/ui/Section';
import { Col, Container } from '@swordhealth/ui-corporate';
import styled from 'styled-components';

export const CustomSection = styled(Section)`
  --mask-size: ${SPACE_DESKTOP};
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) calc(100% - var(--mask-size)),
    rgba(255, 255, 255, 0)
  );
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    --mask-size: ${SPACE_TABLET};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    --mask-size: ${SPACE_MOBILE};
  }
`;

export const CustomContainer = styled(Container)`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    display: block;
  }
`;
export const MobileContainer = styled.div`
  display: block;

  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    display: none;
  }
`;

export const Indicator = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;
export const SemiCircle = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 100%;

  & + & {
    margin-top: 4px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 2px solid ${(props) => props.theme.colors.grey.default};
  }

  &.active {
    background-color: ${(props) => props.theme.colors.grey.default};
  }
`;
export const ImageSticky = styled.div`
  position: sticky;
  top: max(90px, -342px + 50vh);
  height: calc(100vh - 100px);
  max-height: 683px;
`;

export const MobileRow = styled(Row)`
  flex-wrap: unset;
  padding-right: 36px;
  padding-left: 36px;

  & + & {
    margin-top: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 44px;
    &:first-of-type {
      margin-top: 0px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-flow: column-reverse nowrap;
    display: unset;
    margin-top: 16px;
    padding-right: 24px;
    padding-left: 24px;
    & + & {
      margin-top: 48px !important;
    }
  }
`;

export const TextCol = styled(Col)`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 32px;
  }
`;
