import Image from 'next/image';

import getImgSrcWorkaround from '@/utils/images';
import { CardContainer, ImageWrapper, Text, ImageContainer } from './style';

export default function Card({ id, image, title }) {
  return (
    <CardContainer>
      <ImageContainer>
        <ImageWrapper>
          <Image
            id={`${id}_image`}
            src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes.url)}
            alt={image?.alternativeText ?? image?.data?.attributes.alternativeText ?? ''}
            fill
          />
        </ImageWrapper>
      </ImageContainer>
      <Text size="lg" weight="semibold" id={`${id}_title`}>
        {title}
      </Text>
    </CardContainer>
  );
}
