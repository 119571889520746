import styled from 'styled-components';

export const TitleWrapper = styled.div`
  max-width: 640px;
  padding-bottom: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 40px;
  }
`;
export const List = styled.ul`
  display: grid;
  grid-template-columns: ${(props) => (props.$colspan === 4 ? `repeat(4, 1fr)` : `repeat(3, 1fr)`)};
  column-gap: ${(props) => (props.$colspan === 4 ? `40px` : `83px`)};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${(props) => (props.$colspan === 4 ? `40px` : `10%`)};
    row-gap: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  h6 {
    @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
      font-size: ${(props) => props.theme.font.body.size.lg};
    }
    font-weight: ${(props) => props.theme.font.weight.semibold};
  }
`;

export const Icon = styled.figure`
  position: relative;
  width: 80px;
  height: 80px;
  min-width: 80px;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-top: 16px;
`;
