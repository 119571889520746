import Image from 'next/image';
import AnimateHeight from 'react-animate-height';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faClock } from '@fortawesome/free-regular-svg-icons';

import { Content, Icon, Time, TitleInner, Wrapper, Number, IconTitle } from './styles';
import { MobileImageBox, ImageWrapper } from '../styles';

import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import { Heading } from '@swordhealth/ui-corporate';

import { Paragraph } from '@swordhealth/ui-corporate';

const Card = ({ content, isActive, onClick, number, isMobile }) => {
  if (!content) return null;
  const { title, description, time, image, id } = content;

  const imgData = getImgData(image);

  return (
    <Wrapper $isActive={isActive} {...(isMobile ? { as: 'article' } : { onClick })}>
      <MobileImageBox>
        <ImageWrapper>
          <Image
            id={`card-image-${id}`}
            alt={imgData?.alternativeText || ''}
            src={getImgSrcWorkaround(imgData?.url)}
            fill
            sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 790px"
          />
        </ImageWrapper>
      </MobileImageBox>
      {number && (
        <Number id={`card-number-${id}`} aria-hidden="true">
          {number}
        </Number>
      )}
      <Content $isActive={isActive}>
        <TitleInner number={!!number}>
          <Heading id={`card-title-${id}`} as="p" size="lg" weight="semibold">
            {title}
          </Heading>
          <IconTitle $isActive={isActive}>
            <Image id={`card-content-image-${id}`} src="/icons/arrow-card.svg" alt="" fill />
          </IconTitle>
        </TitleInner>
        <AnimateHeight
          duration={500}
          height={isActive || isMobile ? 'auto' : 0}
          aria-hidden={!isMobile && !isActive}
        >
          <Paragraph as="span" size="md" id={`card-description-${id}`}>
            {description}
          </Paragraph>
          {time && (
            <Time>
              <Icon>
                <FontAwesomeIcon id={`time-icon-${id}`} icon={faClock} className="icon" />
              </Icon>
              {time}
            </Time>
          )}
        </AnimateHeight>
      </Content>
    </Wrapper>
  );
};

export default Card;
