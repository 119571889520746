import styled, { css } from 'styled-components';
import { Heading, Paragraph } from '@swordhealth/ui-corporate';
import Section from '@/components/ui/Section';

export const CustomSection = styled(Section)`
  overflow-x: clip;
  background-color: white;
  ${(props) =>
    props.$hasSection &&
    css`
      margin-bottom: 0px;
    `}
`;

export const Title = styled(Heading)`
  margin: 0 auto;
  max-width: 800px;

  @media (min-width: ${({ theme }) => theme.breakpoints.min_md}px) {
    text-align: center;
  }
`;

export const Subtitle = styled(Paragraph)`
  margin: 0 auto;
  max-width: 800px;
  padding-top: 24px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    margin: 0;
    padding-top: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    padding-top: 16px;
    text-align: left;
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const Slider = styled.div`
  --card-margin-width: 12px;
  --card-width: 100%;
  height: 100%;
  cursor: grab;

  .embla__container {
    align-items: stretch;
    display: flex;
    touch-action: pan-y;
  }

  .embla__slide {
    display: flex;
    flex: 0 0 auto;
    width: var(--card-width);
    margin-left: 0;
    margin-right: var(--card-margin-width);
    pointer-events: none;
    position: relative;

    @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
      min-width: 212px;
    }

    &.slide-active {
      pointer-events: auto;
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding-top: 80px;
  row-gap: 40px;
  column-gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 64px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 40px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  justify-content: flex-start;
  width: ${(props) => (props.$cards === 4 ? `calc(25% - 18px)` : `calc(100% / 3 - 16px)`)};
  padding: 4px;
  background-color: ${(props) => props.theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;

  img {
    width: 100%;
    border-radius: 12px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 32px 0 0;
`;

export const CardTitle = styled.h6`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: ${(props) => props.theme.typography.family.title};

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    font-size: ${(props) => props.theme.font.body.size.md};
    font-weight: ${(props) => props.theme.font.weight.semibold};
  }
`;

export const CardDesc = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;
